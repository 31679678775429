<template>
   <div class="app_update_button_container">
      <button class="update-alert" v-if="updateAvailable" @click="refreshApp">
        <md-icon>update</md-icon>
        New update available! Click to update.
      </button>
    </div>
  <img class="background-image" :src="$store.state.bgImage" />
  <div class="background">
    <Header v-if="$route.name !== 'LogIn' && $route.name !== 'ResetPassword' && $route.name !== 'Registration' && $store.state.user.id" />
    <router-view />
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import Swal from "sweetalert2";

export default {
  components: {
    Header,
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateAvailable: false,
      updateExists: false,
    };
  },
  created() {
    document.addEventListener("swUpdate", this.showUIUpdate, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // To prevent infinite loop refresh
        if (this.refreshing) {
          return;
        }

        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showUIUpdate(e) {
      console.log(e)
      console.log('UI has been activated and should show alert')
      this.registration = e.detail;
      this.updateAvailable = true;
      Swal.fire({
        title: "New Update!",
        text:
          "New update available! Click Update to refresh. Remember to save any changes you made before continuing",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "UPDATE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          this.refreshApp();
        } else if (result.isDismissed) {
          if (this.updateExists)
            setTimeout(() => {
              this.showUIUpdate(e);
            }, 300000);
        }
      });
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/main.scss"></style>
