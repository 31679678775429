//Auth

import { auth, onAuthStateChanged } from "./config/firebaseInit";

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueSweetalert2 from "vue-sweetalert2";
import Datepicker from "@vuepic/vue-datepicker";

//Styles
import "sweetalert2/dist/sweetalert2.min.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "./registerServiceWorker";

let app;
onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = createApp(App)
      .use(store)
      .use(VueSweetalert2, {
        confirmButtonColor: "#41b882",
        cancelButtonColor: "#ff7674",
      })
      .use(router)
      .component("Datepicker", Datepicker)
      .mount("#app");
  }
});
